/* Footer */
.Footer {
    background-color: $footer-bg-color;
    border-bottom: 5px solid $cc-blue;

    // Top
    &-top {
        padding-top: u($spacing-unit * 2.8);
        padding-bottom: u($spacing-unit--xs);
        align-items: center;

        &--media,
        &--contact,
        .Nav--dataMain {
            margin-bottom: u($spacing-unit);
        }

        &--media {
            max-width: u(120);
            width: 100%;
        }

        .Nav-item {
            margin-left: u($spacing-unit);

            @include mq($from: $viewport--lg) {
                margin-left: u($spacing-unit--lg);
            }

            &,
            .Nav-link {
                color: $white;
                font-size: u(17);
                line-height: u(30);
                font-weight: bold;
                text-decoration: none;
            }
        }

        &--contact {
            margin-right: auto;
        }
    }

    // Bottom
    &-bottom {
        // border-top: 1px solid rgba($black, .1);
        display: flex;
        align-items: center;
        justify-content: space-between;
        // padding-top: u($spacing-unit--xs);
        padding-bottom: u($spacing-unit--sm);
    }
}
