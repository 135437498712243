.FormPage {

    .Banner-content{
        @include fluid-type(padding-left, $viewport--xs, $viewport--xl, 20, 80);
        @include fluid-type(padding-right, $viewport--xs, $viewport--xl, 20, 80);
    }
    h1 {
        @include fluid-type(font-size, $viewport--xs, $viewport--xl, 28, 36);
        hyphens: auto;
    }
}

.Form--custom {
    .Enquetes-item--title,
    .Form-title {
        font-size: u(18);
        color: $cc-blue;
        margin-top: u($spacing-unit--lg);
        margin-bottom: u($spacing-unit--sm);
        font-weight: 600;
    }
}
