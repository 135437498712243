/* Button */

.Button {
    @keyframes MoveArrow {
        0% {
            transform: translate(0, -50%);
        }

        50% {
            transform: translate(7px, -50%);
        }

        100% {
            transform: translate(0, -50%);
        }
    }

    @keyframes MoveArrowLarge {
        0% {
            transform: translate(0, -50%);
        }

        50% {
            transform: translate(-14px, -50%);
        }

        100% {
            transform: translate(0, -50%);
        }
    }
    @keyframes MoveArrowNew{
        0% {
            transform: translateX(0)
        }

        50% {
            transform: translateX(7px)
        }

        100% {
            transform: translateX(0)
        }
    }

    // Primary
    &--primary {
        background-color: $cc-blue;
        color: $white;
        text-transform: uppercase;
        font-weight: bold;
        letter-spacing: 1px;

        .Button-icon {
            margin-left: u(14);

            * {
                stroke: $white;
            }
        }

        &:not(.Button--outline) {

            &:active,
            &:focus,
            &:hover,
            &.is-active {
                color: $white;

                .Button-icon {
                    animation: MoveArrowNew .8s infinite;
                }
            }
        }

        &.Button--outline {
            color: $grey--300;

            &:active,
            &:focus,
            &:hover,
            &.is-active {
                color: $grey--500;
            }
        }
    }

    // Secondary
    &--secondary {
        background-color: transparent;
        color: $cc-blue;
        text-transform: uppercase;
        font-weight: bold;
        letter-spacing: 1px;
        border: 2px solid $cc-blue;
        display: inline-flex;
        align-items: center;
        transition: color .6s, background-color .6s;

        .Button-icon {
            margin-left: u(14);

            * {
                stroke: $cc-blue;
            }
        }




        &:active,
        &:focus,
        &:hover,
        &.is-active {
            color: $white;
            background-color: $cc-blue;

            .Button-icon {
                animation: MoveArrowNew .8s infinite;

                * {
                    stroke: $white;
                }
            }
        }

    }

    // link
    &--link {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: $cc-blue;
        text-align: left;
        font-size: 18px;
        font-weight: 600;
        border-bottom: 1px solid $cc-blue;
        padding: u(0, 0, 19, 0);
        margin-bottom: u(19);
        line-height: normal;

        &--last {
            margin-bottom: 0;
            padding-bottom: 0;
            border-bottom: none;
        }

        .Button--arrow {
            margin-left: u($spacing-unit--sm);
            position: relative;
            top: u(8);

            * {
                stroke: $cc-blue;
            }
        }

        &:not(.Button--outline) {

            &:active,
            &:focus,
            &:hover,
            &.is-active {
                color: $cc-blue;

                .Button--arrow {
                    animation: MoveArrowLarge .8s infinite;
                }
            }
        }

        &.Button--outline {
            color: $blue;

            &:active,
            &:focus,
            &:hover,
            &.is-active {
                color: $blue--700;
            }
        }
    }

    // white
    &--white {
        background-color: $white;
        color: $cc-blue;
        padding: u(11, 16) !important;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: u(15);
        border: 2px solid $white;
        transition: color .5s, border-color .5s;

        @include mq($until: $viewport--lg - 1) {
            border-color: $cc-blue;
            background-color: transparent;
        }

        .Helpdesk-icon {
            @include dimensions(16, 18);
            margin-right: u(16);

            * {
                stroke: $cc-blue;
                transition: stroke .5s;
            }
        }

        &:active,
        &:focus,
        &:hover,
        &.is-active {
            background-color: $cc-blue;
            color: $white;
            border-color: $white;
            .Helpdesk-icon{
                * {
                    stroke: $white;
                }
            }
        }

    }

    &--arrow {
        @include dimensions(16, 10);
        position: relative;
        top: u(3);
        transform: translate(0, -50%);
    }

    // Info
    &--info {
        background-color: $blue;
        color: $white;

        &:not(.Button--outline) {

            &:active,
            &:focus,
            &:hover,
            &.is-active {
                background-color: $blue--700;
                color: $white;
            }
        }

        &.Button--outline {
            color: $blue;

            &:active,
            &:focus,
            &:hover,
            &.is-active {
                color: $blue--700;
            }
        }
    }

    // Success
    &--success {
        background-color: $green;
        color: $white;

        &:not(.Button--outline) {

            &:active,
            &:focus,
            &:hover,
            &.is-active {
                background-color: $green--700;
                color: $white;
            }
        }

        &.Button--outline {
            color: $green;

            &:active,
            &:focus,
            &:hover,
            &.is-active {
                color: $green--700;
            }
        }
    }

    // Warning
    &--warning {
        background-color: $orange;
        color: $white;

        &:not(.Button--outline) {

            &:active,
            &:focus,
            &:hover,
            &.is-active {
                background-color: $orange--700;
                color: $white;
            }
        }

        &.Button--outline {
            color: $orange;

            &:active,
            &:focus,
            &:hover,
            &.is-active {
                color: $orange--700;
            }
        }
    }

    // Danger
    &--danger {
        background-color: $red;
        color: $white;

        &:not(.Button--outline) {

            &:active,
            &:focus,
            &:hover,
            &.is-active {
                background-color: $red--700;
                color: $white;
            }
        }

        &.Button--outline {
            color: $red;

            &:active,
            &:focus,
            &:hover,
            &.is-active {
                color: $red--700;
            }
        }
    }

    // Outline
    &--outline {
        background-color: transparent;
        border-color: currentColor;
    }

    // Large
    &--large {
        @include font-size($button-font-size * 1.25, false);
        padding-right: u($button-padding--r * 2);
        padding-left: u($button-padding--l * 2);
    }

    // Small
    &--small {
        @include font-size($button-font-size * .75, false);
        padding-right: u($button-padding--r / 1.5);
        padding-left: u($button-padding--l / 1.5);
    }

    // Full
    &--full {
        display: block;
        width: 100%;
    }

    // Bare
    &--bare {
        border: 0;
        border-radius: 0;
        padding: 0;
    }
}
