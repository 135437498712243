.HomepageBlocks {
    padding-top: 0 !important;
    padding-bottom: 0 !important;



    .HomepageBlock {

        margin: u($spacing-unit * 3, 0);

        @include mq($from: $viewport--lg) {
            margin: u($spacing-unit * 4.8, 0);
        }

        &.CustomPadding {
            padding-top: u($spacing-unit * 3);

            @include mq($from: $viewport--lg) {
                padding-top: u($spacing-unit * 4.8);
            }
        }

        &-top {
            @include mq($from: $viewport--sm) {
                display: flex;
                align-items: flex-end;
                justify-content: space-between;
            }
        }

        &-content {
            max-width: 660px;

            h2 {
                letter-spacing: 1px;
            }

            p {
                @include font-size(22);
                line-height: u(30);

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        &-navigation {
            @include mq($from: $viewport--sm) {
                margin-left: u($spacing-unit);
            }

            display: flex;
            justify-content: space-between;
            min-width: calc((24px * 2) + 14px);
            max-width: calc((24px * 2) + 14px);

            &--item {
                background-image: none;

                &:focus{
                   outline: none !important;
                }

                &:hover {
                    cursor: pointer;
                }

                &.is-disabled {
                    .HomepageBlock-navigation--arrow {

                        * {
                            stroke: $cc-grey--dark;
                        }
                    }
                }

                &.Slider-button--prev {
                    transform: scaleX(-1);
                }
            }

            &--arrow {
                @include dimensions(24, 17);

            }
        }

        &--background {
            position: relative;
            padding: u($spacing-unit * 3, 0);

            @include mq($from: $viewport--lg) {
                padding: u($spacing-unit * 4.8, 0);
            }

            &::before {
                content: '';
                position: absolute;
                width: 100vw;
                top: 0;
                height: 100%;
                z-index: -1;
                background-color: $white;
                left: -40px;

                @include mq($until: $viewport--md - 1) {
                    left: -20px;
                }

                @include mq($from: $structure-width + 80) {
                    left: calc(((100vw - #{u($structure-width)}) / 2) * -1);
                }
            }
        }
    }

    .ContentServices {

        .ContentServices-item {
            margin-bottom: u($spacing-unit--sm);

            .Inner {
                position: relative;
                text-decoration: none;
                color: #f1f1f1;
                overflow: hidden;

                h3 {
                    @include font-size(25, 0);
                    letter-spacing: 0.69px;
                    font-weight: bold;
                    position: absolute;
                    bottom: 35px;
                    left: 35px;
                    z-index: 2;
                }

                &::before {
                    content: '';
                    @include position(absolute, 0, 0, 0, 0, 1);
                    width: 100%;
                    height: 100%;
                    background-color: rgba($cc-blue, .6);
                    opacity: 0;
                    @include transition(opacity, .3s)
                }

                &:hover {
                    &::before {
                        opacity: 1;
                    }

                    .ContentServices-item--media img {
                        transform: scale(1.1);
                    }

                }
            }

            &--media {
                img {
                    @include transition(transform, .3s);
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
    }

    .ContentBlock {

        .HomepageBlock-content,
        .HomepageBlock-navigation {
            margin-bottom: u($spacing-unit--lg);
        }
    }

    .SliderBlock {

        .HomepageBlock-content,
        .HomepageBlock-navigation {
            margin-bottom: u($spacing-unit * 2);
        }

        .Slider-item {
            width: auto;

            * {
                height: 390px;
            }
        }
    }

    .CtaBlock {
        h2 {

            &,
            a {
                color: $cc-blue;
                @include font-size(35, 0);
                line-height: u(40);
                transition: color .5s;
            }

            a{
                &:hover{
                    text-decoration: underline;
                    color:  darken($cc-blue, 25%)
                }
            }

        }
    }

    .JobBlock {
        @include mq($from: $viewport--md) {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        &-media {
            width: 100%;

            @include mq($until: $viewport--md - 1) {
                margin-bottom: u($spacing-unit);
            }

            @include mq($from: $viewport--md) {
                margin-bottom: auto;
                max-width: u(510);
                margin-right: u($spacing-unit--sm);
            }
        }

        &-content {
            width: 100%;

            @include mq($from: $viewport--md) {
                margin-left: u($spacing-unit--sm);
                max-width: u(577);
            }

            h2 {
                letter-spacing: 1px;
            }

            p {
                @include font-size(16);
                line-height: u(24);
            }
        }
    }

}
