.News {
    margin-top: u($spacing-unit);
    margin-bottom: u($spacing-unit);

    @include mq($from: $viewport--lg) {
        margin-top: u($spacing-unit * 2.2);
        margin-bottom: u($spacing-unit * 1.8);
    }

    .Intro {
        margin-bottom: u($spacing-unit * 2.2);

        &-content {
            max-width: u(660);

            h2 {
                letter-spacing: 1px;
            }

            p {
                font-size: u(22);
                line-height: u(30);
                color: $cc-dark;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}

.NewsBanner {
    margin-top: u($spacing-unit * 2.2);

    @include mq($from: $viewport--md) {
        display: flex;
        justify-content: space-between;
    }

    &-content {
        width: 100%;

        @include mq($from: $viewport--md) {
            max-width: u(355);
        }

        h1 {
            font-size: u(36);
            letter-spacing: 1px;
            line-height: normal;
        }

        span {
            margin-bottom: u($spacing-unit * 2.4);
        }

        .Intro p {
            font-size: u(22);
        }

        p {
            font-size: u(16);
        }
    }

    &-media {
        width: 100%;

        @include mq($from: $viewport--md) {
            max-width: u(680);
            margin-left: u($spacing-unit);
        }
    }
}
