.Vacatures{
    margin-top: u($spacing-unit);
    margin-bottom: u($spacing-unit);

    @include mq($from: $viewport--lg) {
        margin-top: u($spacing-unit * 2.2);
        margin-bottom: u($spacing-unit * 1.8);
    }

    .Intro{
        margin-bottom: u($spacing-unit * 2.2);
        &-content{
            max-width: u(660);
            h2 {
                letter-spacing: 1px;
            }

            p {
                font-size: u(22);
                line-height: u(30);
                color: $cc-dark;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}