/* General */

// HoverState
.HoverState {
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    &::before {
        transition: opacity .5s;
        content: '';
        @include position(absolute, 0, 0, 0, 0, 1);
        background-color: rgba($black, .3);
        opacity: 0;
    }

    img {
        transition: transform .4s;
    }

    &-icon {
        position: absolute;
        opacity: 0;
        transition: opacity .5s;
        z-index: 2;
    }

    &:hover {
        &::before {
            opacity: 1;
        }

        .HoverState-icon {
            opacity: 1;
        }

        img {
            transform: scale(1.1);
        }
    }
}

// Overlay on image
.ContactPage,
.LinksPage {

    .Banner {
        &-media {
            position: relative;

            &::before {
                content: "";
                @include position(absolute, 0, 0, 0, 0, 1);
                max-height: u(250);
                background-image: linear-gradient(to bottom, rgba($cc-grey--background, .95) 45%, rgba(51, 51, 51, 0));
            }
        }

        &-content {
            z-index: 2;
        }
    }
}

// LinksPage
.LinksPage {
    .Banner {
        &-content {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }
}

// Grid-item
.Grid-item {
    text-decoration: none;

    &--media {
        position: relative;
        overflow: hidden;

        img {
            transition: transform .4s;
        }

        .Cross-icon {
            position: absolute;
            @include dimensions(22, 22);
            right: 15px;
            bottom: 15px;
            z-index: 1;
        }

        &::before {
            content: '';
            position: absolute;
            right: 0;
            bottom: 0;
            @include dimensions(54, 54);
            background-color: $cc-blue;
            transition: width .4s, height .4s, opacity .4s;
            z-index: 1;
        }

        &:hover {
            &::before {
                width: 100%;
                height: 100%;
                opacity: .7;
            }

            img {
                transform: scale(1.1);
            }
        }
    }

    &--info {
        margin-top: u(21);

        p {
            @include font-size(18, 1);
            font-weight: bold;
            line-height: normal;
            color: $black;
        }

        .Label {
            @include font-size(16, 0);
            font-weight: 600;
            line-height: normal;
            color: $cc-grey--dark;
        }
    }

}

// Transparant headers
.ServicesPage,
.VacaturesPage,
.DefaultPage,
.ContactPage,
.LinksPage,
.FormPage {
    .Header {
        background-color: transparent;

        &.is-notTop {
            background-color: $cc-grey--background;
        }

        .show-nav & {
            @include mq($until: $viewport--lg - 1) {
                background-color: $cc-grey--background;
            }
        }
    }
}

// Scroll bar overflow
.HomePage {
    overflow-x: hidden;
}

// margin left
.Margin-left {
    @include fluid-type(margin-left, $structure-width, $structure-width + 250, 0, $spacing-unit * 2.5)
}

// <strong> color overlay
.Strong-color {
    strong {
        font-weight: normal;
        color: $cc-blue;
    }
}

// LazySizes
.LazyLoad,
.LazyLoading {
    opacity: 0;
}

.LazyLoaded {
    @include transition(opacity, 300ms);
    opacity: 1;
}

// Tabbing
body:not(.is-tabbing) {

    span:focus,
    button:focus,
    input:focus,
    select:focus,
    textarea:focus {
        outline: none;
    }
}
