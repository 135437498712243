.BannerWrapper {
    padding-top: 0 !important;
    position: relative;
    margin-top: u($structure-header-height * -1);
    margin-bottom: u($spacing-unit * 2.8);
}

.Banner {

    @include mq($from: $viewport--md, $until: $viewport--lg - 1) {
        @include grid(minmax(auto, 200px) 120px 1fr, minmax($structure-header-height + 80px, auto) 1fr 50px);
    }

    @include mq($from: $viewport--lg) {
        @include grid(auto 120px 1fr, minmax($structure-header-height + 80px, auto) 1fr 50px);
    }

    &-content {
        @include grid-area(2, 4, 2, 4);
        background-color: $white;
        padding: u($spacing-unit * 2, $spacing-unit);

        @include mq($from: $viewport--sm, $until: $viewport--md - 1) {
            padding: u($spacing-unit * 3.2, $spacing-unit * 2);
        }

        @include mq($from: $viewport--md) {
            padding: u($spacing-unit * 3.2, $spacing-unit * 4.8);
        }

        h1{
            font-size: u(36);
            letter-spacing: 1px;
            line-height: normal;
        }

        .Intro{
            font-size: u(22);
            line-height: u(30);
        }
    }

    &-media {
        @include mq($until: $viewport--md - 1) {

            &,
            img {
                max-height: 280px;
                width: 100%;
                object-fit: cover;
            }
        }

        @include mq($from: $viewport--md) {
            @include grid-area(1, 3, 1, 3);
            max-width: 515px;
        }
    }

    &-content,
    &-media {
        position: relative;

        @include mq($until: $viewport--md - 1) {
            width: calc(100% + 40px);
            right: 20px;
        }

        @include mq($from: $viewport--md, $until: $structure-width - 1) {
            width: calc(100% + 80px);
            right: 40px;
        }

        @include mq($from: $structure-width) {
            width: calc(100% + 110px);
            right: 110px;
        }
    }
}
