.ContactPage {


    .Banner {
        .GoogleMaps {
            @include fluid-type(min-width, $viewport--md, $viewport--xl, 150, 515);
            min-height: u(250);

            >div {
                @include fluid-type(min-width, $viewport--md, $viewport--xl, 150, 515);

                min-height: u(250);
                height: 100%;
                width: 100%;
            }
        }

        &-content {
            @include mq($from: $viewport--sm) {
                display: flex;
                justify-content: space-between;
            }

            @include mq ($until: $viewport--lg - 1) {
                padding: u(80, 40) !important;
            }

            padding: u(80, 75, 80, 120) !important;

            .Info {
                @include mq($from: $viewport--sm) {
                    max-width: u(300);
                }

                a,
                p {
                    text-decoration: none;
                    color: $cc-dark;
                }

                a {
                    &:hover {
                        text-decoration: underline;
                    }
                }

                h1 {
                    letter-spacing: 1px;
                    font-size: u(36);
                    line-height: normal;
                }

                h4 {
                    @include font-size(22, 5);
                    line-height: u(30);
                }

                span {
                    color: $cc-blue;
                    text-transform: uppercase;
                    font-weight: bold;
                    margin-right: u($spacing-unit--sm);
                }
            }

            .Form {
                width: 100%;

                @include mq($from: $viewport--sm) {
                    @include fluid-type(max-width, $viewport--md, $viewport--xl, 250, 360);
                }
            }
        }
    }
}
