.HomepageBanner {
    background-color: $cc-grey--background;

    .Container {

        padding-top: 0 !important;
        @include grid(1fr, minmax(50px, auto) 1fr minmax(50px, auto));
        position: relative;



        @include mq($from: $structure-width) {
            &::before {
                content: '';
                position: absolute;
                top: 0;
                height: 50%;
                background-color: $cc-blue;
            }
        }

        @include mq($from: $structure-width, $until: $structure-width + 80) {
            &::before {
                right: 0;
                width: 40px;
            }
        }

        @include mq($from: $structure-width + 80) {
            &::before {
                right: calc(((100vw - #{u($structure-width + 80)}) / 2) * -1);
                width: calc(((100vw - #{u($structure-width)}) / 2) * 1);
            }
        }

    }

    .HomepageBanner-media {
        @include grid-area(1, 2, 1, 4);
        z-index: 1;
        position: relative;
        &::before{
            content: "";
            @include position(absolute, 0, 0, 0, 0);
            background-color: rgba(#3C3C3C, .35);
        }

        @include mq($until: $viewport--md - 1) {
            width: calc(100% + 40px);
            right: 20px;
        }

        @include mq($from: $viewport--md, $until: $structure-width - 1) {
            width: calc(100% + 80px);
            right: 40px;
        }

        @include mq($from: $structure-width) {
            width: calc(100% + 110px);
            right: 110px;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .HomepageBanner-content {
        z-index: 2;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        @include grid-area(1, 2, 2, 3);

        &--label,
        &--title {
            line-height: u(40);
            color: $cc-grey;
        }

        &--label {
            @include font-size(25, 0);
            font-weight: 300;
        }

        &--title {
            @include font-size(35, 35);
            font-weight: bold;
        }
    }

}
