.Nav--footer {

    .Nav-list {
        margin-left: u($spacing-unit--sm * -1);
    }

    .Nav-item {
        padding-left: u($spacing-unit--sm);

        &, .Nav-link{
            color: $cc-grey--light;
            font-size: u(11);
            line-height: u(22);
            font-weight: 300;
        }
    }

    .Nav-link{}
}
