/* Navigation */
.Navigation {

    @include mq($until: $viewport--lg - 1) {
        margin-left: auto;
    }

    @include mq($from: $viewport--lg) {
        width: 100%;
    }

    &-toggle {
        display: block;
    }

    &-body {
        display: flex;

        @include mq($from: $viewport--lg) {
            margin-left: u($spacing-unit);
        }

        &--buttons {
            display: flex;

            @include mq($until: $structure-width + 99) {
                flex-direction: column;

                .Button--white {
                    margin-top: u($spacing-unit--sm);
                }
            }

        }

        >.Nav {
            @include mq($from: $viewport--lg) {
                padding-left: u($spacing-unit);
            }
        }

        @include mq($until: $viewport--lg - 1) {
            @include position(fixed, u($structure-header-height), 0, 0, 0, 1);
            @include transition(#{opacity, visibility});
            background-color: $header-bg-color;
            flex-direction: column;
            padding: u($spacing-unit);
            height: calc(100vh - #{u($structure-header-height)});
            justify-content: center;

            html:not(.show-nav) & {
                opacity: 0;
                visibility: hidden;
            }
        }
    }

    .Button {
        font-size: u(15) !important;
        letter-spacing: normal;
        text-transform: inherit;
        padding: u(9, 23);
        white-space: nowrap;
        font-weight: 600;
        transition: background-color .6s, color .6s;

        @include mq($until: $viewport--lg - 1) {
            max-width: 380px;
            margin: u($spacing-unit--xs, auto);
            // margin-bottom: u(85);
        }

        .HomePage & {
            @include mq($from: $viewport--lg) {

                &.Button--secondary {
                    border-color: $white;
                    color: $white;

                    &:hover {
                        background-color: $white;
                        color: $cc-blue;
                    }
                }
            }
        }

        &.Button--secondary {
            &:hover {
                background-color: $cc-blue;
                color: $white;
            }
        }
    }

    .Button {}
}

/* Types */
@import '6-components/navigation/main';
@import '6-components/navigation/lang';
@import '6-components/navigation/footer';
