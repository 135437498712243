/* Logo */
.Logo {
    flex-shrink: 0;

    svg, img {
        display: block;
        width: u(200);
        @include mq($from: $viewport--md){
            width: u(273);
        }
    }
}
