/* Header */
.Header {
    background-color: $header-bg-color;
    padding-top: u($spacing-unit);
    padding-bottom: u($spacing-unit);
    transition: all .6s !important;

    position: relative;

    @include mq($from: $viewport--lg) {
        .HomePage & {
            &::before {
                content: '';
                position: absolute;
                right: 0;
                top: 0;
                height: 100%;
                background-color: $cc-blue;
                width: 255px;

                @include mq($from: $structure-width + 100) {
                    width: calc(((100vw - #{u($structure-width)}) / 2) + 225px);
                }
            }

            &.Header--login {
                &::before {
                    @include mq($from: $structure-width + 100) {
                        width: calc(((100vw - #{u($structure-width)}) / 2) + 345px);
                    }
                }
            }
        }

    }

    >.Container {
        display: flex;
        align-items: center;
    }

    &--sticky {
        transition: transform .6s;
        transform: translateY(0%);
        position: sticky;
        top: 0;


        .show-nav & {
            transform: translateY(0%) !important;
        }

        &.is-pinned {
            transform: translateY(0%);
            box-shadow: 0 0 10px 0 rgba($black, 0.25);
        }

        &.is-unpinned {
            transform: translateY(-100%);
        }

        &.is-top {
            box-shadow: none;
        }

        &.is-notTop {}
    }
}
