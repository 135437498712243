.ServicesOverviewPage {
    .Intro {
        margin-top: u($spacing-unit--lg);
        margin-bottom: u($spacing-unit * 3.2);

        @include mq($from: $viewport--md) {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
        }

        &-content {
            max-width: u(660);
            width: 100%;

            @include mq($from: $viewport--md) {
                margin-right: u($spacing-unit);
            }

            h2 {
                letter-spacing: 1px;
            }

            p {
                font-size: u(22);
                line-height: u(30);
                color: $cc-dark;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        .Button {
            @include mq($until: $viewport--md - 1) {
                margin-top: u($spacing-unit);
            }

            @include mq($from: $viewport--md) {
                white-space: nowrap;
            }
        }
    }

    .ServicePage{
        padding-bottom: 0;
    }

    .Services {
        &-item {
            margin-bottom: u($spacing-unit * 3);
            @include mq($from: $viewport--md) {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: u($spacing-unit * 4.8);
            }


            &:nth-child(even) {
                flex-direction: row-reverse;

                .Services-item--content {
                    @include mq($from: $viewport--md) {
                        margin-left: u($spacing-unit);
                        margin-right: u($spacing-unit);
                    }
                }
            }

            &:nth-child(odd) {

                .Services-item--content {
                    @include mq($from: $viewport--md) {
                        margin-right: u($spacing-unit);
                    }

                    @include fluid-type(margin-left, $structure-width, $structure-width + 250, 0, $spacing-unit * 2.5)
                }
            }

            &--content {
                @include mq($from: $viewport--md) {
                    max-width: u(285);
                }

                @include mq($until: $viewport--md - 1) {
                    margin-bottom: u($spacing-unit);
                }

                width: 100%;

                h3 {
                    font-size: u(32);
                    line-height: u(41);
                    letter-spacing: .89px;
                    color: $cc-dark;
                }

                p {
                    font-size: u(16);
                    line-height: u(24);
                    color: $cc-dark;
                }
            }

            &--media {
                @include mq($from: $viewport--md) {
                    max-width: u(783);
                    margin-bottom: auto;
                }

                width: 100%;
            }
        }
    }
}
