.VacaturesPage {
    overflow-x: hidden;
}

.ContentBlocks {
    // margin-top: u($spacing-unit);
    margin-bottom: u($spacing-unit);

    @include mq($from: $viewport--lg) {
        // margin-top: u($spacing-unit * 2.2);
        margin-bottom: u($spacing-unit * 1.8);
    }

    h2,
    h3,
    h4 {
        letter-spacing: 1px;
        line-height: normal;
    }

    .Max-width {
        max-width: u(710);
    }

    .ContentBlock {
        margin: u($spacing-unit * 3, 0);

        @include mq($from: $viewport--lg) {
            margin: u($spacing-unit * 4.8, 0);
        }
    }

    .Intro {
        font-size: u(22);
        line-height: u(30);
    }

    .MediaBlock {
        // margin-top: u($spacing-unit * 3);
        // margin-bottom: u($spacing-unit * 3);

        @include mq($from: $viewport--md) {
            display: flex;
            justify-content: space-between;
            align-items: center;
            // margin-top: u($spacing-unit * 4.8);
            // margin-bottom: u($spacing-unit * 4.8);
        }


        &.MediaBlock--left {
            flex-direction: row-reverse;

            .MediaBlock-content {
                @include fluid-type(margin-right, $structure-width, $structure-width + 250, 0, $spacing-unit * 3.8);

                @include mq($from: $viewport--md) {
                    margin-left: u($spacing-unit);
                    max-width: u(475);
                }
            }

            .MediaBlock-media {
                @include mq($from: $viewport--md) {
                    max-width: u(475);
                }
            }
        }

        &.MediaBlock--right {

            .MediaBlock-content {
                @include mq($from: $viewport--md) {
                    margin-right: u($spacing-unit);
                    max-width: u(340);
                }

                @include fluid-type(margin-left, $structure-width, $structure-width + 250, 0, $spacing-unit * 2.5)
            }

            .MediaBlock-media {
                @include mq($from: $viewport--md) {
                    max-width: u(680);
                }
            }
        }

        &-content {
            width: 100%;

            @include mq($until: $viewport--md - 1) {
                margin-bottom: u($spacing-unit);
            }

            h3 {
                font-size: u(32);
                line-height: u(41);
                letter-spacing: .89px;
                color: $cc-dark;
                white-space: pre;
            }

            p {
                font-size: u(16);
                line-height: u(24);
                color: $cc-dark;
            }
        }

        &-media {
            width: 100%;

            @include mq($from: $viewport--md) {
                margin-bottom: auto;
            }
        }
    }

    .SliderBlock {
        // margin-top: u($spacing-unit);
        // margin-bottom: u($spacing-unit * 2);

        // @include mq($from: $viewport--md) {
        //     // margin-top: u($spacing-unit * 2.2);
        //     margin-bottom: u($spacing-unit * 4.8);
        // }

        @include mq($until: $viewport--md - 1) {
            width: calc(100% + 40px);
            right: 20px;
        }

        @include mq($from: $viewport--md, $until: $structure-width + 79) {
            width: calc(100% + 80px);
            right: 40px;
        }

        @include mq($from: $structure-width + 80, $until: $structure-width + 229) {
            width: calc(100% + ((100vw - #{u($structure-width)})));
            right: calc(((100vw - #{u($structure-width)}) / 2) * 1);
        }

        @include mq($from: $structure-width + 230) {
            width: calc(100% + 220px);
            right: 110px;
        }

        .Slider-item {
            width: auto;

            * {
                height: u(585);
            }
        }

        .Slider-button--next {
            transform: translate(0, -50%) scaleX(-1) !important;
            right: u(60);
        }

        .Slider-button--prev {
            left: u(60);
        }

        .Slider-button--prev,
        .Slider-button--next {
            background-image: none;
            position: absolute;
            z-index: 1;
            top: 50%;
            transform: translate(0, -50%);

            &:hover {
                cursor: pointer;

                .SliderBlock-arrow {
                    * {
                        stroke: $cc-blue;
                    }
                }
            }
        }
    }
}
