.References {
    padding-bottom: u($spacing-unit * 3) !important;

    @include mq($from: $viewport--lg) {
        padding-bottom: u($spacing-unit * 4.8) !important;
    }

    &-intro {
        margin-bottom: u($spacing-unit * 2);

        @include mq($from: $viewport--md) {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
        }

        &--content {
            max-width: u(467);
            width: 100%;

            @include mq($from: $viewport--md) {
                margin-right: u($spacing-unit);
            }

            h2 {
                letter-spacing: 1px;
            }

            p {
                font-size: u(22);
                line-height: u(30);
                color: $cc-dark;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        .Button {
            @include mq($until: $viewport--md - 1) {
                margin-top: u($spacing-unit);
            }

            @include mq($from: $viewport--md) {
                white-space: nowrap;
            }
        }
    }

    &-items {}

    &-item {
        width: 100%;
        max-width: 100%;

        @include mq($from: $viewport--sm) {
            max-width: calc((100% / 2) - 15px);
        }
    }
}
