/* Cookie */
.CookieConsent {
    @include position(fixed, 0, 0, 0, 0, 4);
    @include transition(#{opacity, visibility}, 200ms, linear);
    background-color: $cookie-background-color;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &:not(.is-active) {
        opacity: 0;
        visibility: hidden;
    }

    &-inner {
        background-color: $cookie-body-color;
        color: $cookie-text-color;
        padding: u($spacing-unit--lg);
        max-width: u(700);
        max-height: 90%;
        width: 90%;
        overflow-y: auto;
    }

    a {
        font-weight: 700;

        &,
        &:active,
        &:focus,
        &:hover {
            color: $cookie-link-color;
        }
    }

    &-close {
        cursor: pointer;
        display: block;
        flex-shrink: 0;
        font-size: 150%;
        margin-left: u($spacing-unit);
        line-height: 1;
        transition-property: opacity;
        transition-duration: 200ms;

        &:not(:hover) {
            opacity: .75;
        }
    }
}
