.CTA {
    background-color: $white;
    padding: u($spacing-unit * 3, 0);

    @include mq($from: $viewport--lg) {
        padding: u($spacing-unit * 4.8, 0);
    }

    &--text {
        .Container {
            max-width: u(665);
            text-align: center;

            h2 {
                max-width: u(484);
                margin-left: auto;
                margin-right: auto;
                letter-spacing: 1px;
                line-height: normal;
            }

            p {
                font-size: u(22);
                line-height: u(30);
                color: $cc-dark;
            }
        }
    }

    &--media {
        .Container {
            @include mq($from: $viewport--md) {
                display: flex;
                justify-content: space-between;
                flex-direction: row-reverse;
                align-items: center;
            }
        }

        .CTA-media {
            width: 100%;

            @include mq($until: $viewport--md - 1) {
                margin-top: u($spacing-unit);
            }

            @include mq($from: $viewport--md) {
                margin-bottom: auto;
                max-width: u(510);
                margin-right: u($spacing-unit);
            }
        }

        .CTA-content {
            width: 100%;

            @include mq($from: $viewport--md) {
                max-width: u(577);
            }

            h2 {
                letter-spacing: 1px;
                line-height: normal;
            }

            p {
                font-size: u(16);
            }
        }
    }
}
