/* Main */
.Nav--main {

    margin: u(0, auto);
    display: flex;
    align-items: center;

    @include mq($until: $viewport--lg - 1) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        // flex-grow: 1;
        margin-bottom: u($spacing-unit--xs);
    }

    // List
    .Nav-list {
        @include mq($from: $viewport--lg) {
            margin-left: u(($spacing-unit * 1.5) * -1) !important;
            margin-right: u($spacing-unit * 1.5) !important;
        }

        @include mq($until: $viewport--lg - 1) {
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
    }

    // Item
    .Nav-item {
        @include mq($from: $viewport--lg) {
            padding-left: u($spacing-unit * 1.5);
            &.Nav-item--home{
                display: none;
            }
        }

        @include mq($until: $viewport--lg - 1) {

            +.Nav-item {
                margin-top: u($spacing-unit--sm);
            }

            .show-nav & {
                @include transition(#{opacity, transform}, #{300ms, 1s}, #{linear, cubic-bezier(.19,1,.22,1)});

                @for $i from 1 through 10 {
                    $delay: (0.02s * $i);

                    &:nth-child(#{$i}) {
                        transition-delay: $delay;
                    }
                }
            }

            html:not(.show-nav) & {
                opacity: 0;
                transform: translateY(#{u($spacing-unit)});
            }
        }
    }

    // Link
    .Nav-link {
        color: $cc-blue;
        font-size: u(15);
        border-bottom: 1px solid transparent;
        transition: border-bottom .4s;

        &:active,
        &:focus,
        &:hover,
        &.is-active {
            color: $cc-blue;
            border-bottom: 1px solid $cc-blue;
        }
    }
}
