.Enquetes {
    &-item {
        &--title {
            color: $cc-blue;
        }

        .Row-items {
            &.Row-items--head {
                background-color: $white;
                display: none;
                min-height: auto !important;
                p{
                    margin-bottom: u(19);
                }

                @include mq($from: $viewport--md) {
                    display: flex;
                }
            }

            &.Row-items--headSmall {
                display: flex;
                @include fluid-type(font-size, $viewport--xs, $viewport--md, 12, 16);

                @include mq($from: $viewport--md) {
                    display: none;
                }
            }

            @include mq($from: $viewport--md) {
                height: 100%;
            }

            min-height: u(43);
            justify-content: space-around;
            align-items: center;
            text-align: center;
            background-color: $cc-grey--background;
        }

        .Row-title {
            min-height: u(43);
            background-color: $cc-grey--background;
            display: flex;
            align-items: center;
            padding: u(10, 20);
            hyphens: auto;

            @include mq($until: $viewport--md - 1) {
                color: $cc-blue;
            }
        }

        .Row-item {
            border-radius: 0 !important;
            @include dimensions(19, 19);
            border: 1px solid $cc-blue;
            background-color: $cc-grey--background;
            transition: background-color .4s;

            &:hover {
                cursor: pointer;
            }

            &.is-active {
                background-color: $cc-blue;
                background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='10px' height='7px' viewBox='0 0 10 7' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3C!-- Generator: Sketch 63.1 (92452) - https://sketch.com --%3E%3Ctitle%3EPath Copy 7%3C/title%3E%3Cdesc%3ECreated with Sketch.%3C/desc%3E%3Cg id='Visual' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Klantentevredenheidsenquete' transform='translate(-1062.000000, -1594.000000)' fill='%23FFFFFF'%3E%3Cpath d='M1070.58484,1594.21864 L1065.32991,1599.12 L1063.41516,1597.33407 C1063.0875,1597.03891 1062.56668,1597.04313 1062.24458,1597.34356 C1061.92247,1597.64399 1061.91795,1598.12977 1062.23441,1598.43538 L1064.73954,1600.77196 C1065.06562,1601.07601 1065.5942,1601.07601 1065.92029,1600.77196 L1071.76559,1595.31994 C1072.08205,1595.01433 1072.07753,1594.52856 1071.75542,1594.22812 C1071.43332,1593.92769 1070.9125,1593.92347 1070.58484,1594.21864 Z' id='Path-Copy-7'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
                background-repeat: no-repeat;
                background-size: 10px 7px;
                background-position: center;
            }
        }

        &--row {
            margin-bottom: u($spacing-unit);

            p {
                margin-bottom: 0;
            }

            &-items {}

            .item {
                border: 1px solid red;
                margin: u(0, 5);
                padding: u(8);

                &:hover {
                    cursor: pointer;
                }
            }
        }
    }
}
